<template>
  <CRow>
    <div class="col-6 col-sm-6 col-md-3 col-lg-3">
      <CWidgetDropdown color="primary" :header="assets" text="All Assets">
        <!-- <template #default> -->
          <!-- <CDropdown
            color="transparent p-0"
            placement="bottom-end"
          >
            <template #toggler-content>
              <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem>Action</CDropdownItem>
            <CDropdownItem>Another action</CDropdownItem>
            <CDropdownItem>Something else here...</CDropdownItem>
            <CDropdownItem disabled>Disabled action</CDropdownItem>
          </CDropdown>
        </template> -->
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="[65, 59, 84, 84, 51, 55, 40]"
            point-hover-background-color="primary"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </div>
    <div class="col-6 col-sm-6 col-md-3 col-lg-3">
      <CWidgetDropdown color="info" :header="ma + mp" text="All Members">
        <!-- <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
            :caret="false"
          >
            <template #toggler-content>
              <CIcon name="cil-location-pin"/>
            </template>
            <CDropdownItem>Action</CDropdownItem>
            <CDropdownItem>Another action</CDropdownItem>
            <CDropdownItem>Something else here...</CDropdownItem>
            <CDropdownItem disabled>Disabled action</CDropdownItem>
          </CDropdown>
        </template> -->
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="[1, 18, 9, 17, 34, 22, 11]"
            point-hover-background-color="info"
            :options="{ elements: { line: { tension: 0.00001 }}}"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </div>
    <div class="col-6 col-sm-6 col-md-3 col-lg-3">
      <CWidgetDropdown
        color="success"
        :header="depo"
        text="Total Depo"
      >
        <!-- <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
          >
            <template #toggler-content>
              <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem>Action</CDropdownItem>
            <CDropdownItem>Another action</CDropdownItem>
            <CDropdownItem>Something else here...</CDropdownItem>
            <CDropdownItem disabled>Disabled action</CDropdownItem>
          </CDropdown>
        </template> -->
        <template #footer>
          <CChartLineSimple
            class="mt-3"
            style="height:70px"
            background-color="rgba(255,255,255,.2)"
            :data-points="[78, 81, 80, 45, 34, 12, 40]"
            :options="{ elements: { line: { borderWidth: 2.5 }}}"
            point-hover-background-color="warning"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </div>
    <div class="col-6 col-sm-6 col-md-3 col-lg-3">
      <CWidgetDropdown
        color="danger"
        :header="wd"
        text="Total WD"
      >
        <!-- <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
          >
            <template #toggler-content>
             <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem>Action</CDropdownItem>
            <CDropdownItem>Another action</CDropdownItem>
            <CDropdownItem>Something else here...</CDropdownItem>
            <CDropdownItem disabled>Disabled action</CDropdownItem>
          </CDropdown>
        </template> -->
        <template #footer>
          <CChartBarSimple
            class="mt-3 mx-3"
            style="height:70px"
            background-color="rgb(250, 152, 152)"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </div>
     <div class="col-12 col-sm-6 col-md-3 col-lg-3">
        <CWidgetIcon
          :header="ma"
          text="Active Members"
          color="gradient-warning"
          :icon-padding="false"
        >
          <CIcon name="cil-people" width="24"/>
        </CWidgetIcon>
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-lg-3">
        <CWidgetIcon
          :header="mp"
          text="Passive Members"
          color="gradient-danger"
          :icon-padding="false"
        >
          <CIcon name="cil-people" width="24"/>
        </CWidgetIcon>
    </div>
  </CRow>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'
import axios from "axios";

let user = JSON.parse(localStorage.getItem('user'));
export default {
  name: 'WidgetsDropdown',
  components: { CChartLineSimple, CChartBarSimple },
  mounted(){
    this.loadData();     
  },
  data() {
    return {
      wallet :  "0  USDT",
      assets: " 0 USDT",
      depo: " 0 USDT",
      wd: " 0 USDT",
      dr: "0",
      dq: "0",
      tq: "0",
      at: "0",
      ma: 0,
      mp: 0,
    };
  },
  methods: {
    loadData: function() {
          let id_member = user.id_member;
          let idm;
          if(id_member=="0") {
            idm = "";
          }
          else{
            idm = id_member;
          }
          axios
            .get(
              process.env.VUE_APP_BASE_URL+"api/public/index.php/dashboardstat/"+idm+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
              user
            )
            .then((response) => {
              // if (response.data == "sukses") {
                
                this.assets = parseFloat(response.data.assets).toFixed(0) + " USDT ";
                this.wallet = parseFloat(response.data.wallet).toFixed(0) + " USDT ";
                this.depo = parseFloat(response.data.depo).toFixed(0) + " USDT ";
                this.wd = parseFloat(response.data.wd).toFixed(0) + " USDT ";
                this.dr = parseFloat(response.data.dr) + " ";
                this.dq = parseFloat(response.data.dq) + " ";
                this.tq = parseFloat(response.data.tq) + " ";
                this.at = parseFloat(response.data.at) + " ";
                this.ma = parseFloat(response.data.ma) ;
                this.mp = parseFloat(response.data.mp) ;
                

                // this.myModal2 = true;
              // } else {
              //   // alert(JSON.stringify(user));
              //   this.myModal2 = true;
              // }
            });
      
    },
  },
}
</script>
